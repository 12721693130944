import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

/**
 * @typedef {{
 * name: string,
 * speechService: string,
 * }} Bot
 */

/**
 * @type {React.Context<Bot>}
 */
export const BotsContext = React.createContext()

export function useBots() {
  return useContext(BotsContext)
}

/**
 * Provides a context for the bots
 * @param {Object} props - the component props
 * @param {JSX.Element} => void} props.children - the children components
 * @returns {JSX.Element}
 */

export function BotsProvider({ children }) {
  const botContext = useMemo(
    () => ({
      /**
       * @type {Bot}
       */
      bot: {
        name: 'default',
        speechService: 'Azure',
      },
    }),
    []
  )

  return (
    <BotsContext.Provider value={botContext}>{children}</BotsContext.Provider>
  )
}

BotsProvider.propTypes = {
  children: PropTypes.node,
}
